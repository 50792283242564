import React, { FC, useMemo } from 'react'
import { formatCurrency } from '@nzsb/shopnx-ui'

import { DataTable } from '../DataTable'

export interface InvoiceOrderLine {
  id: number
  sku: string
  description: string
  uom: string
  orderedQty: number
  suppliedQty: number
  backOrderQty: number
  price: number
  priceWithGst: number
  value: number
  valueWithGST: number
}

export interface IInvoiceDetailsDataTableProps {
  componentId?: string
  invoiceOrderLineData: InvoiceOrderLine[]
}

export const InvoiceDetailsDataTable: FC<IInvoiceDetailsDataTableProps> = ({
  componentId,
  invoiceOrderLineData
}: IInvoiceDetailsDataTableProps) => {
  const getCellContent = (row: any, rowHeader: string) => {
    switch (rowHeader) {
      case 'item':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px]'>{row?.original?.sku}</span>
        )
      case 'description':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px]'>
            {row?.original?.description}
          </span>
        )
      case 'uom':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px] uppercase'>
            {row?.original?.uom}
          </span>
        )
      case 'ordered':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px] justify-end'>
            {row?.original?.orderedQty}
          </span>
        )
      case 'supplied':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px] justify-end'>
            {row?.original?.suppliedQty}
          </span>
        )
      case 'bk/or':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px] justify-end'>
            {row?.original?.backOrderQty}
          </span>
        )
      case 'price':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px] justify-end'>
            {formatCurrency(row?.original?.price)}
          </span>
        )
      case 'value':
        return (
          <span className='flex text-N-600 font-400 py-1 min-h-[46px] justify-end'>
            {formatCurrency(row?.original?.value)}
          </span>
        )
      case 'Mobile':
        return (
          <div className='flex flex-col py-2'>
            <span className='flex flex-col text-N-700 font-500'>{row?.original?.sku}</span>

            <div className='flex flex-col pt-4'>
              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Description:</span>
                <span className='flex flex-col text-N-600 font-400'>
                  {row?.original?.description}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>UOM:</span>
                <span className='flex flex-col text-N-600 font-500 uppercase'>
                  {row?.original?.uom}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Ordered:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {row?.original?.orderedQty}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Supplied:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {row?.original?.suppliedQty}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>BK / OR</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {row?.original?.backOrderQty}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Price:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {formatCurrency(row?.original?.price)}
                </span>
              </div>

              <div className='flex items-start py-1'>
                <span className='w-[140px] text-N-500 font-400'>Value:</span>
                <span className='flex flex-col text-N-600 font-500'>
                  {formatCurrency(row?.original?.value)}
                </span>
              </div>
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  const cellHeaderClassName = `leading-[16px] h-[44px] z-0 !bg-N-50`

  const MIN_WIDTH_140PX = '!min-w-[140px]'

  const DESKTOP_COLUMNS = useMemo(() => {
    const columnData = [
      {
        header: 'Item',
        accessor: 'sku',
        label: 'item',
        cellClassName: '!min-w-[126px]'
      },
      {
        header: 'Description',
        accessor: 'description',
        label: 'description',
        cellClassName: '!min-w-[385px]'
      },
      {
        header: 'UOM',
        accessor: 'uom',
        label: 'uom',
        cellClassName: MIN_WIDTH_140PX
      },
      {
        header: 'Ordered',
        accessor: 'orderedQty',
        label: 'ordered',
        cellClassName: MIN_WIDTH_140PX,
        align: 'right'
      },
      {
        header: 'Supplied',
        accessor: 'suppliedQty',
        label: 'supplied',
        cellClassName: MIN_WIDTH_140PX,
        align: 'right'
      },
      {
        header: 'BK / OR',
        accessor: 'backOrderQty',
        label: 'bk/or',
        cellClassName: '!min-w-[119px]',
        align: 'right'
      },
      {
        header: 'Price',
        accessor: 'price',
        label: 'price',
        cellClassName: '!min-w-[112px] ma!x-w-[113px]',
        align: 'right'
      },
      {
        header: 'Value',
        accessor: 'value',
        label: 'value',
        cellClassName: '!min-w-[116px] !max-w-[117px]',
        align: 'right'
      }
    ]

    return columnData.map(({ header, accessor, label, cellClassName, align }) => ({
      Header: header,
      accessor: accessor,
      cellHeaderClassName: `${cellHeaderClassName} ${cellClassName}`,
      Cell: ({ row }: any) => getCellContent(row, label),
      align: align
    }))
  }, [])

  const MOBILE_COLUMNS = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Mobile')
        }
      }
    ]
  }, [])

  const tableProps = {
    isOrderHistoryTable: true,
    enablePagination: false,
    data: invoiceOrderLineData || []
  }

  return (
    <div
      className='flex w-full h-fit border-x border-t border-N-50 rounded'
      data-component-id={`invoice-details-order-line-table-${componentId}`}>
      {/* Desktop View */}
      <DataTable mainDivClassName='hidden lg:!block' columns={DESKTOP_COLUMNS} {...tableProps} />

      {/* Mobile, Tab View */}
      <DataTable
        mainDivClassName='lg:!hidden'
        isShowHeaders={false}
        columns={MOBILE_COLUMNS}
        {...tableProps}
      />
    </div>
  )
}

export default InvoiceDetailsDataTable
