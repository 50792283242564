import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Accordion, CategoryFilter, CheckboxFilter, debounce } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { PriceFilter } from 'components/molecules/PriceFilter'

export interface ISideBarFiltersProps {
  brandIds: string[]
  categoryFilterHeaderClasses?: string
  className?: string
  colourIds: string[]
  expandedCategoryList: any
  filterData: any
  maxPrice?: number
  minPrice?: number
  selectedCategoryIds: any
  setExpandedCategoryList: any
  setSelectedCategoryIds: any
  isMobile?: boolean
  priceKey?: any
  returnResultCount?: any
}

export const SideBarFilters: FC<ISideBarFiltersProps> = ({
  brandIds,
  categoryFilterHeaderClasses,
  className,
  colourIds,
  expandedCategoryList,
  filterData,
  maxPrice,
  minPrice,
  selectedCategoryIds,
  setExpandedCategoryList,
  setSelectedCategoryIds,
  isMobile,
  priceKey,
  returnResultCount,
  ...restProps
}: ISideBarFiltersProps) => {
  const SideBarFiltersClasses = CN(
    `side-bar-filters h-fit min-w-[296px] flex-col pt-[8px]`,
    { 'hidden lg:!flex': !isMobile },
    { flex: isMobile },
    className
  )

  const selectedCategoryIdsArray = selectedCategoryIds?.map((item: any) => {
    return parseInt(item)
  })

  const router = useRouter()
  const categories = filterData?.categories || []
  const brandList = filterData?.brands || []
  const colorList = filterData?.colors || []
  const { minPrice: startValue, maxPrice: endValue } = filterData || {}
  const [expanded, setExpanded] = useState<false | number>(0)

  // Category Filter
  const [expandedBrandFilter, setExpandedBrandFilter] = useState<false | number>(0)

  // Color filter
  const [expandedColorFilter, setExpandedColorFilter] = useState<false | number>(0)
  const [expandedPriceFilter, setExpandedPriceFilter] = useState<false | number>(0)

  const [preparedCategoryTree, setPreparedCategoryTree] = useState<any>(
    categories?.map((item: any) => {
      let checkedStatus = 'unchecked'
      if (selectedCategoryIdsArray?.includes(item.id)) {
        checkedStatus = 'checked'
      }
      // if any of the child categories are selected, then parent category should be indeterminate
      else if (
        item?.childCategories?.some((child: any) => selectedCategoryIdsArray?.includes(child.id))
      ) {
        checkedStatus = 'indeterminate'
      }

      return {
        id: item.id,
        label: item.name,
        value: item.name,
        counter: item.count,
        checked: checkedStatus,
        children: item.childCategories.map((child: any) => {
          return {
            id: child.id,
            label: child.name,
            value: child.name,
            counter: child.count,
            checked:
              selectedCategoryIdsArray?.includes(item.id) ||
              selectedCategoryIdsArray?.includes(child.id)
                ? 'checked'
                : 'unchecked'
          }
        })
      }
    })
  )

  useEffect(() => {
    setPreparedCategoryTree(
      categories?.map((item: any) => {
        let checkedStatus = 'unchecked'
        if (selectedCategoryIdsArray?.includes(item.id)) {
          checkedStatus = 'checked'
        } else if (
          item?.childCategories?.some((child: any) => selectedCategoryIdsArray?.includes(child.id))
        ) {
          checkedStatus = 'indeterminate'
        }

        return {
          id: item.id,
          label: item.name,
          value: item.name,
          counter: item.count,
          expanded: expandedCategoryList?.includes(item.id),
          checked: checkedStatus,
          children: item.childCategories.map((child: any) => {
            return {
              id: child.id,
              label: child.name,
              value: child.name,
              counter: child.count,
              checked:
                selectedCategoryIdsArray?.includes(item.id) ||
                selectedCategoryIdsArray?.includes(child.id)
                  ? 'checked'
                  : 'unchecked'
            }
          })
        }
      })
    )
  }, [selectedCategoryIds])

  const onSelect = () => {
    const toBeUpdatedQueryCategoryIds: number[] = []
    // eslint-disable-next-line prefer-const
    let preparedCategoryTreeCopy: any = [...preparedCategoryTree]

    preparedCategoryTreeCopy?.map((item: any) => {
      if (item?.children?.length > 0) {
        if (item.children.every((a: any) => a.checked === 'checked')) {
          toBeUpdatedQueryCategoryIds.push(item.id)
        } else {
          item.children.map((child: any) => {
            if (child.checked === 'checked') {
              toBeUpdatedQueryCategoryIds.push(child.id)
            }
          })
        }
      } else if (item.checked === 'checked') {
        toBeUpdatedQueryCategoryIds.push(item.id)
      }
    })

    const { page, categoryIds, expandList, ...restQuery } = router.query

    if (toBeUpdatedQueryCategoryIds.length === 0) {
      router.push(
        {
          pathname: router.pathname,
          query: restQuery
        },
        undefined,
        { scroll: false }
      )
    } else {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...restQuery,
            categoryIds: toBeUpdatedQueryCategoryIds.join(',')
          }
        },
        undefined,
        { scroll: false }
      )
    }
  }

  const handlePriceFilter = debounce(({ max, min, field }: any) => {
    const { page, minPrice: queryMinPrice, maxPrice: queryMaxPrice, ...restQuery } = router.query
    if (min === startValue && max === endValue) {
      router.push(
        {
          pathname: router.pathname,
          query: restQuery
        },
        undefined,
        { scroll: false }
      )
    } else {
      const getMinValue = () => {
        if (field === 'min' && max <= min) {
          if (max > 100) {
            return max - 100
          } else {
            return 0
          }
        }
        return min
      }

      router.push(
        {
          pathname: router.pathname,
          query: {
            ...restQuery,
            minPrice: getMinValue(),
            maxPrice: field === 'max' && min >= max ? min + 100 : max
          }
        },
        undefined,
        { scroll: false }
      )
    }
  }, 1000)

  const [brands, setBrands] = useState<any>(
    brandList?.map((brand: any) => ({
      label: brand?.name,
      value: brand?.id,
      counter: brand?.count,
      checked: brandIds?.includes(brand?.id?.toString())
    }))
  )

  useEffect(() => {
    const selectedBrands = brandList?.map((brand: any) => ({
      label: brand?.name,
      value: brand?.id,
      counter: brand?.count,
      checked: brandIds?.includes(brand?.id?.toString())
    }))
    setBrands(selectedBrands)
    const availableBrands = selectedBrands.filter((brand: any) => brand.checked)
    const { brandIds: queryBrandIds, ...restQuery } = router.query
    if (availableBrands?.length > 0) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...restQuery,
            brandIds: availableBrands.map((brand: any) => brand?.value)?.join(',')
          }
        },
        undefined,
        { shallow: true }
      )
    }
  }, [brandList, brandIds])

  const [colors, setColors] = useState<any>(
    colorList?.map((color: any) => ({
      label: color?.name,
      colors: color?.code.split(','),
      value: color?.colorId,
      counter: color?.count,
      checked: colourIds?.includes(color?.colorId.toString())
    }))
  )

  useEffect(() => {
    const selectedColors = colorList?.map((color: any) => ({
      label: color?.name,
      colors: color?.code.split(','),
      value: color?.colorId,
      counter: color?.count,
      checked: colourIds?.includes(color?.colorId.toString())
    }))
    setColors(selectedColors)
    const availableColors = selectedColors.filter((color: any) => color.checked)
    const { colourIds: queryColourIds, ...restQuery } = router.query
    if (availableColors?.length > 0) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...restQuery,
            colourIds: availableColors.map((color: any) => color?.value)?.join(',')
          }
        },
        undefined,
        { shallow: true }
      )
    }
  }, [colorList, colourIds])

  /** Select brand and append to query */
  const onBrandSelection = (webIdsOfBrands: number[]) => {
    const { page, brandIds: queryBrandIds, ...restQuery } = router.query
    if (webIdsOfBrands.length === 0) {
      router.push(
        {
          pathname: router.pathname,
          query: restQuery
        },
        undefined,
        { scroll: false }
      )
    } else {
      router.push(
        {
          // pathname: router.pathname,
          // query: {
          //   ...router.query,
          //   brandIds: queryBrandIds
          //     ? queryBrandIds + ',' + webIdsOfBrands.join(',')
          //     : webIdsOfBrands.join(',')
          // }
          pathname: router.pathname,
          query: {
            ...restQuery,
            brandIds: webIdsOfBrands.join(',')
          }
        },
        undefined,
        { scroll: false }
      )
    }
  }

  /** Select color and append to query */
  const onColorSelection = (colorCodes: string[]) => {
    const { page, colourIds: queryColourIds, ...restQuery } = router.query
    if (colorCodes.length === 0) {
      router.push(
        {
          pathname: router.pathname,
          query: restQuery
        },
        undefined,
        { scroll: false }
      )
    } else {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...restQuery,
            colourIds: colorCodes.join(',')
          }
        },
        undefined,
        { scroll: false }
      )
    }
  }

  useEffect(() => {
    if (endValue < (maxPrice || 0)) {
      const min = startValue
      const max = endValue
      const { page, minPrice: queryMinPrice, maxPrice: queryMaxPrice, ...restQuery } = router.query

      if (min === startValue && max === endValue) {
        router.push(
          {
            pathname: router.pathname,
            query: restQuery
          },
          undefined,
          { scroll: false }
        )
      } else {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...restQuery,
              minPrice: min,
              maxPrice: max
            }
          },
          undefined,
          { scroll: false }
        )
      }
    }
  }, [startValue, endValue])

  return (
    <div className={SideBarFiltersClasses} {...restProps}>
      {returnResultCount === 0 && (
        <div className='flex w-full items-center justify-center border-t border-t-N-100 pt-[42px]'>
          <h3 className='text-N-700'>No available filters</h3>
        </div>
      )}

      {returnResultCount !== 0 && (
        <Accordion
          className={CN('w-full py-[16px]', categoryFilterHeaderClasses)}
          headerClasses='!normal-case !text-N-700'
          setExpanded={setExpanded}
          expanded={expanded}
          item={{
            id: 0,
            content: (
              <CategoryFilter
                componentId='category-filter'
                categoryList={preparedCategoryTree}
                onSelect={onSelect}
                onExpandedListChange={(expandedList: any) => {
                  setExpandedCategoryList(expandedList)
                }}
              />
            ),
            title: 'Product Categories'
          }}
          variant='white'
        />
      )}
      {returnResultCount !== 0 && brands?.length > 0 && (
        <Accordion
          className='w-full py-[16px]'
          headerClasses='!normal-case !text-N-700'
          setExpanded={setExpandedBrandFilter}
          expanded={expandedBrandFilter}
          item={{
            id: 0,
            content: (
              <CheckboxFilter
                componentId='brand-filter'
                key={brands}
                textFieldProps={{ placeholder: 'Search Brand', id: 'brand' }}
                options={brands}
                name='brand-filter'
                onSelect={vl => {
                  onBrandSelection(vl.map((item: any) => item.value))
                }}
                maxHeight={500}
              />
            ),
            title: 'Brand'
          }}
          variant='white'
        />
      )}
      {returnResultCount !== 0 && (
        <Accordion
          className='w-full py-[16px]'
          headerClasses='!normal-case !text-N-700'
          setExpanded={setExpandedPriceFilter}
          expanded={expandedPriceFilter}
          item={{
            id: 0,
            content: (
              <PriceFilter
                key={`${startValue} ${endValue} ${minPrice} ${maxPrice} - ${priceKey}`}
                className='w-full'
                // Lets keep || otherwise the values are not updated as needed
                minPrice={minPrice || startValue}
                maxPrice={maxPrice || endValue}
                startValue={startValue}
                endValue={endValue}
                onChange={handlePriceFilter}
              />
            ),
            title: 'Price'
          }}
          variant='white'
        />
      )}
      {returnResultCount !== 0 && colors?.length > 0 && (
        <Accordion
          className='w-full py-[16px]'
          headerClasses='!normal-case !text-N-700'
          setExpanded={setExpandedColorFilter}
          expanded={expandedColorFilter}
          item={{
            id: 0,
            content: (
              <CheckboxFilter
                componentId='colour-filter'
                key={colors}
                options={colors}
                hideSearchBar
                name='colour-filter'
                onSelect={vl => {
                  onColorSelection(vl.map((item: any) => item.value))
                }}
                maxHeight={500}
              />
            ),
            title: 'Colour'
          }}
          variant='white'
        />
      )}
    </div>
  )
}

export default SideBarFilters
